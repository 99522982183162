import React from 'react';
import './Footer.css'; 

const Footer = () => {
  return (
    <footer className="text-dark text-center py-3">
      <p className="mb-0" id="scroll-to-top">Go to the top <b style={{ fontSize: '20px', fontWeight: '800' }}>^</b></p>
    </footer>
  );
}

export default Footer;
