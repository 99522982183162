import React, { useState, useRef, useEffect } from "react";
import "./MP3Player.css";

function MP3Player() {
  const [metadata, setMetadata] = useState({});
  const [audioSrc, setAudioSrc] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [category, setCategory] = useState("");
  const [folderName, setFolderName] = useState("");
  const [existingFolders, setExistingFolders] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState("");
  const [showCreateFolder, setShowCreateFolder] = useState(false);

  const audioRef = useRef(null);

  const categories = [
    "Recitations",
    "Recitations from Haramain Taraweeh",
    "Non-Hafs Recitations",
    "Recitations with Translations",
  ];

  useEffect(() => {
    if (category) {
      fetchExistingFolders(category);
    }
  }, [category]);

  const fetchExistingFolders = async (category) => {
    try {
      const response = await fetch(`https://qaricourse-50nzc2g5.b4a.run//folders/${category}`);
      if (!response.ok) {
        throw new Error("Failed to fetch folders");
      }
      const folders = await response.json();
      setExistingFolders(folders);
    } catch (error) {
      console.error("Error fetching folders:", error);
      alert("Error fetching folders.");
    }
  };

  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
    setSelectedFolder("");
    setFolderName("");
  };

  const handleFolderNameChange = (e) => {
    setFolderName(e.target.value);
  };

  const handleCreateFolder = async () => {
    if (!category || !folderName) {
      alert("Please select a category and enter a folder name.");
      return;
    }

    try {
      const response = await fetch("https://qaricourse-50nzc2g5.b4a.run//create-folder", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ category, folderName }),
      });

      if (!response.ok) {
        throw new Error("Failed to create folder");
      }

      const updatedFolders = await response.json();
      setExistingFolders(updatedFolders);
      setFolderName("");
      setShowCreateFolder(false);
      alert("Folder created successfully!");
    } catch (error) {
      console.error("Error creating folder:", error);
      alert("Error creating folder.");
    }
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];

    if (file && category && selectedFolder) {
      try {
        const fileURL = URL.createObjectURL(file);
        setAudioSrc(fileURL);

        const metadata = await getFileMetadata(file);
        setMetadata(metadata);

        await uploadFileToServer(file);
      } catch (error) {
        console.error("Error processing file:", error);
      }
    } else {
      alert("Please select a category and folder before uploading.");
    }
  };

  const getFileMetadata = async (file) => {
    return {
      title: file.name,
      artist: "Unknown Artist",
    };
  };

  const uploadFileToServer = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("artist", metadata.artist || "Unknown Artist");
    formData.append("category", category);
    formData.append("folderName", selectedFolder);

    try {
      const response = await fetch("https://qaricourse-50nzc2g5.b4a.run//upload", {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Failed to upload file");
      }

      console.log("File uploaded successfully");
    } catch (error) {
      console.error("Error uploading file:", error);
      alert(`Error: ${error.message}`);
    }
  };

  const handlePlayPause = () => {
    const audio = audioRef.current;
    if (isPlaying) {
      audio.pause();
    } else {
      audio.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleTimeUpdate = () => {
    const audio = audioRef.current;
    setCurrentTime(audio.currentTime);
    setDuration(audio.duration);
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const handleSeek = (e) => {
    const audio = audioRef.current;
    const seekTime = (e.target.value / 100) * audio.duration;
    audio.currentTime = seekTime;
  };

  return (
    <div className="mp3-player">
      <div className="category-selector">
        <label>Select Category:</label>
        <select value={category} onChange={handleCategoryChange}>
          <option value="">--Select Category--</option>
          {categories.map((cat) => (
            <option key={cat} value={cat}>
              {cat}
            </option>
          ))}
        </select>
      </div>

      {category && (
        <>
          <div className="existing-folders">
            <label>Select Existing Folder:</label>
            <select
              value={selectedFolder}
              onChange={(e) => setSelectedFolder(e.target.value)}
            >
              <option value="">--Select Folder--</option>
              {existingFolders.map((folder) => (
                <option key={folder} value={folder}>
                  {folder}
                </option>
              ))}
            </select>
          </div>

          <div className="file-upload">
            <label htmlFor="file-upload-input" className="file-upload-label">
              Choose an MP3 File
            </label>
            <input
              type="file"
              id="file-upload-input"
              accept="audio/mp3"
              onChange={handleFileChange}
              className="file-input"
            />
          </div>

          <button className="toggle-create-folder" onClick={() => setShowCreateFolder(!showCreateFolder)}>
            {showCreateFolder ? "Cancel" : "Create New Folder"}
          </button>

          {showCreateFolder && (
            <div className="folder-creation">
              <label>New Folder Name:</label>
              <input
                type="text"
                value={folderName}
                onChange={handleFolderNameChange}
                placeholder="Enter folder name"
              />
              <button onClick={handleCreateFolder}>Create Folder</button>
            </div>
          )}
        </>
      )}

      {metadata.title && (
        <div className="metadata">
          <div className="track-info">
            <p className="title">{metadata.title}</p>
            <p className="artist">{metadata.artist}</p>
          </div>
          <div className="controls">
            <button onClick={handlePlayPause} className="play-pause-btn">
              {isPlaying ? "❚❚" : "►"}
            </button>
            <audio
              ref={audioRef}
              src={audioSrc}
              onTimeUpdate={handleTimeUpdate}
              onEnded={() => setIsPlaying(false)}
            ></audio>
            <input
              type="range"
              min="0"
              max="100"
              value={(currentTime / duration) * 100 || 0}
              onChange={handleSeek}
              className="progress-bar"
            />
            <span className="time">
              {formatTime(currentTime)} / {formatTime(duration)}
            </span>
          </div>
        </div>
      )}
    </div>
  );
}

export default MP3Player;
