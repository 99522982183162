import React, { useState, useEffect } from "react";
import { parseBlob } from "music-metadata";
import ReactAudioPlayer from "react-audio-player";
import "./Content.css";

function Content() {
  const [albums, setAlbums] = useState({});
  const [currentAlbum, setCurrentAlbum] = useState(null);
  const [currentTrack, setCurrentTrack] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState("Recitations");

  useEffect(() => {
    const folderMapping = {
      "Recitations": "Recitations",
      "Recitations from Haramain Taraweeh": "Recitations from Haramain Taraweeh",
      "Non-Hafs Recitations": "Non-Hafs Recitations",
      "Recitations with Translations": "Recitations with Translations",
    };

    const loadFilesAndMetadata = async (folderName) => {
      setLoading(true);
      try {
        const folder = folderMapping[folderName];
        if (!folder) throw new Error("Invalid folder name");

        console.log(`Fetching data for folder: ${folder}`);
        const response = await fetch(`https://qaricourse-50nzc2g5.b4a.run//files/${folder}`);
        if (!response.ok) throw new Error("Failed to load files");

        const fileData = await response.json();
        const albumMap = {};
        const metadataPromises = [];

        Object.keys(fileData).forEach((artist) => {
          const files = fileData[artist];
          if (Array.isArray(files)) {
            files.forEach((fileObj) => {
              const fileUrl = fileObj.file;

              const fetchMetadata = fetch(fileUrl)
                .then((response) => response.blob())
                .then((blob) => parseBlob(blob))
                .then((metadata) => {
                  const title = metadata.common.title || "Unknown Title";
                  const trackNumber = metadata.common.track?.no || 0;
                  if (!albumMap[artist]) albumMap[artist] = [];
                  albumMap[artist].push({
                    title,
                    file: fileUrl,
                    trackNumber,
                    artist,
                  });
                })
                .catch((err) =>
                  console.error(`Error fetching metadata for ${fileUrl}: ${err}`)
                );

              metadataPromises.push(fetchMetadata);
            });
          } else {
            console.error(`Expected an array but got: ${files}`);
          }
        });

        await Promise.all(metadataPromises);

        const sortedAlbums = Object.keys(albumMap)
          .sort()
          .reduce((acc, artist) => {
            acc[artist] = albumMap[artist].sort(
              (a, b) => a.trackNumber - b.trackNumber
            );
            return acc;
          }, {});

        setAlbums(sortedAlbums);
      } catch (err) {
        console.error(`Error loading files: ${err.message}`);
        setError(`Error loading files: ${err.message}`);
      } finally {
        setLoading(false);
      }
    };

    loadFilesAndMetadata(activeTab);
  }, [activeTab]);

  const handleAlbumClick = (artist) => {
    setCurrentAlbum(artist);
  };

  const handleTrackPlay = (track) => {
    setCurrentTrack(track);
  };

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
    setCurrentAlbum(null); // Reset the current album when switching tabs
  };

  if (loading) {
    return <div>Loading albums, please wait...</div>;
  }

  if (error) {
    return <div>Error loading files: {error}</div>;
  }

  return (
    <div>
      {/* Navbar Section */}
      <nav className="navbar-container">
        <ul className="nav nav-pills justify-content-center py-3">
          <li className="nav-item">
            <a
              className={`nav-link ${activeTab === "Recitations" ? "active" : ""}`}
              onClick={() => handleTabClick("Recitations")}
            >
              Recitations
            </a>
          </li>
          <li className="nav-item">
            <a
              className={`nav-link ${activeTab === "Recitations from Haramain Taraweeh" ? "active" : ""}`}
              onClick={() => handleTabClick("Recitations from Haramain Taraweeh")}
            >
              Recitations from Haramain Taraweeh
            </a>
          </li>
          <li className="nav-item">
            <a
              className={`nav-link ${activeTab === "Non-Hafs Recitations" ? "active" : ""}`}
              onClick={() => handleTabClick("Non-Hafs Recitations")}
            >
              Non-Hafs Recitations
            </a>
          </li>
          <li className="nav-item">
            <a
              className={`nav-link ${activeTab === "Recitations with Translations" ? "active" : ""}`}
              onClick={() => handleTabClick("Recitations with Translations")}
            >
              Recitations with Translations
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              href="https://qaricourse-50nzc2g5.b4a.run//readquran"
            >
              Read Quran
            </a>
          </li>
        </ul>
      </nav>

      {/* Main Content Section */}
      <div className="mp3-player container mt-5">
        {!currentAlbum ? (
          <AlbumList albums={albums} onAlbumClick={handleAlbumClick} />
        ) : (
          <Album
            artist={currentAlbum}
            files={albums[currentAlbum]}
            goBack={() => setCurrentAlbum(null)}
            onTrackPlay={handleTrackPlay}
          />
        )}
      </div>

      {/* Persistent Audio Player */}
      {currentTrack && (
        <div className="audio-player-fixed">
          <div className="track-info">
            <span className="track-title">{currentTrack.title}</span> -{" "}
            <span className="track-artist">{currentTrack.artist}</span>
          </div>
          <ReactAudioPlayer controls src={currentTrack.file} />
        </div>
      )}
    </div>
  );
}

// Utility function to split array into chunks of 3
const chunkArray = (array, chunkSize) => {
  const result = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    result.push(array.slice(i, i + chunkSize));
  }
  return result;
};

function AlbumList({ albums, onAlbumClick }) {
  const groupedAlbums = groupByFirstLetter(albums);

  return (
    <div className="album-list">
      <h2>All Albums</h2>
      {Object.keys(groupedAlbums).map((letter) => (
        <div key={letter}>
          <h3>
            <span className="letter-circle">{letter}</span>{" "}
            {/* Letter inside the circle */}
          </h3>
          {chunkArray(groupedAlbums[letter], 3).map(
            (artistChunk, chunkIndex) => (
              <div key={chunkIndex} className="album-row">
                {artistChunk.map((artist) => (
                  <div
                    key={artist}
                    className="list-group-item list-group-item-action album-item"
                    onClick={() => onAlbumClick(artist)}
                  >
                    {artist}
                  </div>
                ))}
              </div>
            )
          )}
        </div>
      ))}
    </div>
  );
}

// Utility function to group albums by the first letter
function groupByFirstLetter(albums) {
  return Object.keys(albums).reduce((acc, artist) => {
    const firstLetter = artist.charAt(0).toUpperCase();
    if (!acc[firstLetter]) {
      acc[firstLetter] = [];
    }
    acc[firstLetter].push(artist);
    return acc;
  }, {});
}

function Album({ artist, files, goBack, onTrackPlay }) {
  return (
    <div className="album">
      <h2>{artist}</h2>
      <button className="btn " onClick={goBack}>
        Back to Albums
      </button>
      <ul className="list-group mt-3">
        {files.map((file, index) => (
          <li
            key={index}
            className="list-group-item list-group-item-action track-item"
            onClick={() => onTrackPlay(file)}
          >
            {file.trackNumber}. {file.title}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Content;
