import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header/Header';
import Content from './components/Content/Content';
import Footer from './components/Footer/Footer';
import MP3Player from './components/MP3Player/MP3Player';
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<Content />} />  
          <Route path="/mp3player" element={<MP3Player />} /> 
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
