import React from 'react';
import './Header.css'; 

const Header = () => {
  return (
    <header className="text-white text-center py-4 position-relative">
      <img src="header.jpg" alt="Background Image" className="background-image" />
      <h1 className="mb-0">QariCourse</h1>
      <div className="top-right-buttons">
        <a href="#" className="btn">About</a>
        <a href="#" className="btn">Contact Us</a>
      </div>
    </header>
  );
}

export default Header;
